import React from "react";

import { Container, Segment, Grid, Header, Image, Button } from "semantic-ui-react";
import { SiWhatsapp } from "react-icons/si";

const App = () => {
  return (
    <Container>
      {/* <Image
        src={happyclient1}
        alt="Kozy happy client. User experience."
      ></Image> */}
      <Segment style={{ padding: "8em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle" centered>
          <Grid.Row textAlign="center">
            <Header as="h3" style={{ fontSize: "2rem" }}>
              Sitio en construcción
            </Header>
          </Grid.Row>
          <Grid.Row textAlign="center">
            <Image size="large" src="media/logo.png" alt="kozy logo" />
          </Grid.Row>
          <Grid.Row textAlign="center">
            <Button
              type="submit"
              size="huge"
              href="https://wa.me/5491125339197"
              target="_blank"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "15rem",
              }}
            >
              <SiWhatsapp style={{ marginRight: "0.5rem" }} />
              Cotizar
            </Button>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default App;
